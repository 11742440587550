import React, {PureComponent} from 'react';
import {createPortal} from 'react-dom';
import css from './index.module.scss'
import { connect } from "react-redux";
import video_icon_close from '../../../assets/images/news/video_icon_close.svg'
import {lockScroll} from "../../../utils/methods";

@connect(state => ({isMobile: state.getIn(['app', 'isMobile'])}),)
class Video extends PureComponent{
  constructor() {
    super();
    const doc = window.document;
    // this.unlock = lockScroll();
    this.popupMaskNode = doc.createElement('div');
    doc.body.appendChild(this.popupMaskNode);
    this.el = React.createRef();
    this.popupMaskNode.addEventListener('touchmove', this.stopScroll, false);
  }
  onMaskClick = () => {
    if (this.props.isMobile) {
      this.close();
    }
  };

  stopScroll = (e) => {
    e.preventDefault()
  }

  close = () => {
    // this.unlock();
    this.popupMaskNode.removeEventListener('touchmove', this.stopScroll, false);
    this.props.onClose && this.props.onClose();
  };

  render() {
    let {rate = '56.25%', src, onClose, oAttrs = {}} = this.props;
    return createPortal(
        <div ref={this.el} className={css['video']} onClick={this.onMaskClick}>
          <div className={css['video-box']} onClick={e => e.stopPropagation()}>
            <div className={css['video-rate-control']} style={{paddingTop: rate}}>
              <video width="auto" height="auto" controls="controls" src={src} {...oAttrs}></video>
            </div>
            <img className={css['video-close']} src={video_icon_close} onClick={this.close}></img>
          </div>
        </div>,
      this.popupMaskNode
    );
  }

  componentWillUnmount() {  //在组件从 DOM 中移除之前立刻被调用
    window.document.body.removeChild(this.popupMaskNode);
  }
}

export default Video;
