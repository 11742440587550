import {PureComponent} from 'react';
import css from './index.module.scss'
import Ellipse from "../../../news/components/Ellipse";

class Item extends PureComponent {
  constructor() {
    super();
  }

  render() {
    let {item = {}, onClick} = this.props;
    let {img, content, title = ''} = item;
    return (
        <div className={css['item']} onClick={onClick}>
          <div className={css['item-img']}>
            <img src={img} alt=""/>
          </div>
          <div className={css['item-text']}>
            <p className={`${css['item-text-title']} ellipse`} title={title}>{title}</p>
            <p className={css['item-text-content']}><Ellipse max={110}>{content}</Ellipse></p>
          </div>
        </div>
    );
  }
}

export default Item;