import MapObject from './mapObject'
export default {
  blocks: new MapObject({
    honor: {
      value: '1',
      label: '企业荣誉'
    },
    dynamic: {
      value: '2',
      label: '企业动态'
    },
    information: {
      value: '3',
      label: '行业资讯'
    },
    video: {
      value: '4',
      label: '视频中心'
    },
  }),
  intentionType: new MapObject({
    trial: {
      value: '1',
      label: '申请试用'
    },
    mediaCooperation: {
      value: '2',
      label: '媒体合作'
    },
    channelCooperation: {
      value: '3',
      label: '渠道合作'
    },
  })
};
