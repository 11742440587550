/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 20:31:06
 * @LastEditors: dingxuejin
 */
import {PureComponent as Component} from "react";
import {Link} from "react-router-dom";
import ItemGroup from '../../components/ItemGroup';
import Item from '../../components/Item';
import css from './index.module.scss';
import infoService from '../../../../api/service/info';
import {parseTime, setTdk, scrollTo} from '../../../../utils/methods';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Block from "../../components/Block";
import extension_pic from '../../../../assets/images/news/extension_pic.png';
import enums from '../../../../utils/enum';
import {Spin} from 'antd';
// 引入富文本框样式，保证管理端效果与实际效果尽可能一致
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme
import {setOssUrl} from "@/utils/methods/_common";

function handleData(item = {}) {
    let fcontent = item.fcontent
    let re = /<img [^>]*src=['"]([^'"]+)[^>]*>/gi;
    let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
    let reHttp = new RegExp("http");
    let imgArr = fcontent.match(re);
    if (imgArr) {
        for (let i = 0; i < imgArr.length; i++) {
            let imgSrc = imgArr[i].match(srcReg);
            if (!reHttp.test(imgSrc[1])) {
                fcontent = fcontent.replaceAll(imgSrc[1],setOssUrl(imgSrc[1]));
            }
        }
    }
    return {
        id: item.fid,
        title: item.ftitle,
        time: parseTime(item.fsubmittime, 'YYYY-MM-DD'),
        content: fcontent,
    };
}

function handleList(list) {
    return (list || []).map(item => ({
        id: item.fid,
        title: item.ftitle,
        img: setOssUrl(item.fmainpic),
        time: parseTime(item.fsubmittime, 'YYYY/MM/DD'),
        content: item.fabstract,
        videoUrl: setOssUrl(item.fvideo),
    }));
}

@connect(state => ({isMobile: state.getIn(['app', 'isMobile'])}),)
class VideosCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            list: [],
            isLoading_data: true,
            isLoading_list: true,
            id: this.props.match.params.id,
            type: this.props.match.params.type,
        };
    }

    // 路由相同，参数不同时触发这里，更新数据
    componentWillReceiveProps() {
        setTimeout(() => {
            let obj = {
                id: this.props.match.params.id,
                type: this.props.match.params.type,
            };
            if (obj.id === this.state.id) return;
            this.setState(obj);
            setTimeout(() => {
                this.getInfo();
                this.getList();
                this.scrollToTop();
            });
        });
    }

    componentDidMount() {
        this.getInfo();
        this.getList();
        this.scrollToTop();
    }

    setTdkFun(title, desc) {
        setTdk(title, desc, "");
    }

    scrollToTop() {
        scrollTo(0);
    }

    getList = () => {
        this.setState({isLoading_list: true, list: []});
        infoService.getList({pageNo: 1, pageSize: 3, ftype: this.state.type}).then(res => {
            if (this.props.isMobile && res.list) {
                res.list = res.list.filter(o => o.fid !== this.state.id);
            }
            this.setState({
                list: handleList(res.list)
            });
        }).finally(() => {
            this.setState({isLoading_list: false});
        });
    };

    getInfo = () => {
        this.setState({isLoading_data: true, data: {}});
        infoService.getInfo({fid: this.state.id}).then(res => {
            this.setState({
                data: handleData(res)
            });
            this.setTdkFun(res.ftitle, res.fabstract);
        }).finally(() => {
            this.setState({isLoading_data: false});
        });
    };


    goDetailsPage = (item) => {
        this.props.history.push(`/news/details/${this.state.type}/${item.id}`);
    };

    render() {
        let {title, time, content} = this.state.data || {};
        let list = this.state.list || [];
        let type = this.state.type;
        let linkMap = {
            dynamic: '/news/enterpriseNews',
            information: '/news/industryInformation'
        };
        return (
            <div className={css["dj-details-center"]}>
                <div className={css['dj-details-center-content']}>
                    <Spin spinning={this.state.isLoading_data}>
                        <p className={css['title']}>{title}</p>
                        <p className={css['time']}>{time}</p>
                        <div className={`${css['content']} ql-container ql-snow`}>
                            <div className="ql-editor" dangerouslySetInnerHTML={{__html: content}}></div>
                        </div>
                    </Spin>
                    <div className={css['extension']}>
                        <p>更多精彩行业资讯</p>
                        <p>扫描下方二维码“关注”东经易网！</p>
                        <img src={extension_pic} alt=""/>
                    </div>
                    <Block noMore={this.props.isMobile} title={enums.blocks.getLabel(type)}
                           link={linkMap[enums.blocks.getKey(type)]} theme="more-center">
                        <Spin spinning={this.state.isLoading_list}>
                            <ItemGroup layout={this.props.isMobile ? [[100]] : [[32, 32, 32]]}>
                                {list.map((item, index) => (
                                    <Item key={index} item={item} onClick={() => this.goDetailsPage(item)}></Item>))}
                            </ItemGroup>
                        </Spin>
                        <div className={css["app_more-btn-box"]}>
                            <Link className={css["app_more-btn"]} to="/news/enterpriseNews">查看更多</Link>
                        </div>
                    </Block>
                </div>
            </div>
        );
    }
}

export default withRouter(VideosCenter);
