import {throttle} from "./throttle";

const ScrollBottomListener = (function() {
  let cache = {};

  function addCache(el, target, name, fn, _fn) {
    (cache[name] || (cache[name] = [])).push([el, target, name, fn, _fn]);
  }

  function removeCache(el, target, name, fn) {
    cache[name] = (cache[name] || []).filter(arr => !(arr[0] === el && arr[1] === target && arr[2] === name && arr[3] === fn));
  }

  function getCache(el, target, name, fn) {
    return (cache[name] || []).filter(arr => arr[0] === el && arr[1] === target && arr[2] === name && arr[3] === fn)[0];
  }

  function judgeScrollBottom(el, target, offset = 0) {
    let sceenHeight = document.documentElement.clientHeight;
    let bottom = target.getBoundingClientRect().bottom;
    if (bottom <= sceenHeight + offset) {
      if (el === document || el === window) {
        return true;
      } else {
        let rect = el.getBoundingClientRect();
        if (rect.bottom >= bottom) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * 滚动到底事件监听，用来做滚动加载
   * @param scrollWrap 滚动容器
   * @param target 滚动到底的目标元素
   * @param fn 滚动到底后触发的回调
   */
  function addScrollBottomListener(scrollWrap, target, fn, isImmediate, option = {}) {
    let {offset} = option;
    let _fn = throttle(20, (e) => {
      fn && fn(e, judgeScrollBottom(e.target, target, offset));
    });
    if (isImmediate) _fn({target: scrollWrap});
    if (getCache(scrollWrap, target, 'scroll', fn)) return ;
    addCache(scrollWrap, target, 'scroll', fn, _fn);
    scrollWrap.addEventListener('scroll', _fn)
  }

  /**
   * 移除滚动到底事件监听
   * @param scrollWrap 滚动容器
   * @param target 滚动到底的目标元素
   * @param fn 滚动到底后触发的回调
   */
  function removeScrollBottomListener(scrollWrap, target, fn) {
    let c = getCache(scrollWrap, target, 'scroll', fn);
    if (!c) return;
    removeCache(scrollWrap, target, 'scroll', fn);
    scrollWrap.removeEventListener('scroll', c.pop())
  }
  return {
    addScrollBottomListener,
    removeScrollBottomListener,
  }
})();
export const addScrollBottomListener = ScrollBottomListener.addScrollBottomListener;
export const removeScrollBottomListener = ScrollBottomListener.removeScrollBottomListener;
