/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-07 20:42:25
 * @LastEditors: dingxuejin
 */
import { PureComponent as Component } from "react";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="dj-contact">
                联系我们
            </div >
        );
    }
}

export default Contact;
