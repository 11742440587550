/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-19 13:53:53
 * @LastEditors: dingxuejin
 */
import {lazy, PureComponent as Component} from "react";
import {mapRoute as AppRoute} from "@/router";
import {connect} from 'react-redux';
import './index.scss';

import {Link} from "react-router-dom";
import {throttle} from "../../../utils/methods";
import productService from "../../../api/service/product";

const DropDown = lazy(() => import("./components/dropDown/index"));
const Menu = lazy(() => import("./components/menu/index"));

const href = window.location.href;
const zbcPath = href.includes("www.dongjing.com") ? "http://zbc.djcps.com/" : href.includes("dongjing.onlinecps.cn") ? "http://zbc.onlinecps.cn/" : "http://zbc.cpsol.net/"

@connect(
    state => ({
        isShieldScroll: state.getIn(['header', 'shieldHeaderScrollListen'])
    }),
)
class Header extends Component {
    constructor(props) {
        super(props);
        this.scrollTop = 0;
        this.historyScrollTop = new Array(10);
        this.state = {
            ishover: false,
            active: "",
            isFixed: false,
            appRoute: AppRoute,
            isMenuShow: false
        };
        this.getReleaseProductList();
    }

    getReleaseProductList() {
        productService.getReleaseProductList().then(res => {
            let data = [], panelObj = {}, to = "";
            for (let i of res.list) {
                let path = "";
                if (i.domain.includes("http")) {
                    path = i.domain;
                } else {
                    path = "http://" + i.domain;
                }
                data.push({
                    path: path,
                    description: i.productName,
                    outLink: true
                });
            }
            let router = this.state.appRoute;
            for (let i in router) {
                if (router[i].path === "/product") {
                    data.push({
                        path: zbcPath,
                        description: "经营测评",
                        outLink: true
                    })
                    router[i].path = "";
                    router[i].redirect = "";
                    router[i].outLink = true;
                    router[i].children = data;
                    if (data.length > 0) {
                        router[i].path = data[0].path;
                    }
                }
            }
            this.setState({
                appRoute: router
            });
        }).finally(() => {

        });
    }

    UNSAFE_componentWillMount() {
        this.handleScroll();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = throttle(20, () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.historyScrollTop = new Array(10);
        }, 200);

        let scrollTop = window.pageYOffset;
        this.historyScrollTop.shift()
        this.historyScrollTop.push(scrollTop);
        let baseOffset = 5;
        if (!this.props.isShieldScroll) {
            // 至少要有100ms的运动趋势才做顶部条的状态更新
            if (this.scrollTop <= baseOffset || typeof this.historyScrollTop[this.historyScrollTop.length - 5] === 'number') {
                let isFixed = this.scrollTop <= baseOffset ||  scrollTop < this.historyScrollTop.find(num => typeof num === 'number');
                this.setState({
                    isFixed,
                    isTop: scrollTop <= baseOffset,
                    ...(isFixed ? {} : {ishover: false, active: ''})
                });
            }
        }
        this.scrollTop = scrollTop;
    });

    /**
     *
     * @param {*} e
     * 鼠标移入
     */
    mouseEnter = (key) => {
        this.setState({
            ishover: true,
            active: key
        });
    };
    /**
     *
     * @param {*} e
     * 鼠标移出
     */
    mouseLeave = (e) => {
        this.setState({
            ishover: false,
            active: ""
        });
    };

    changeMenuState = (bool) => {
        this.setState({isMenuShow: bool,});
    }

    render() {
        let {isFixed, isTop, ishover, isMenuShow} = this.state;
        let {layout} = this.props;
        let isWhiteTheme = layout != 1 || ishover || !isTop || isMenuShow;
        return (
            <header className={`dj-header ${ishover ? "hover" : ""} ${isMenuShow ? 'menu-show' : ''} ${isWhiteTheme ? 'is-white' : ''}`} style={{
                position: (this.props.layout == 1) ? "absolute" : "static"
            }}>
                <div className={`${isFixed ? 'isFixed' : ''}`}>
                    <Link to="/" className="log"></Link>
                    {/*<div className={isWhiteTheme ? "log" : "log log1"}></div>*/}
                    <div className="lst">
                        {
                            this.state.appRoute.map((item, index) => {
                                let {path, description, nav, children = [], redirect, outLink} = item;
                                if (redirect) path = path + redirect;
                                children = children.filter(obj => obj.nav !== 0);
                                let tga = "";
                                if (outLink) {
                                    tga = (< a target="_blank" href={path+'?source=siteProductCenter'} onMouseEnter={() => this.mouseEnter(index)}
                                               onMouseLeave={this.mouseLeave} key={index} className="itm">
                                        <span className="des">{description}</span>
                                        {
                                            children.length && (
                                                <DropDown list={children} index={index}
                                                          active={this.state.active} {...this.props}></DropDown>
                                            ) || ''
                                        }
                                    </a>);
                                } else {
                                    tga = (< Link to={path} onMouseEnter={() => this.mouseEnter(index)}
                                                  onMouseLeave={this.mouseLeave} key={index} className="itm">
                                        <span className="des">{description}</span>
                                        {
                                            children.length && (
                                                <DropDown list={children} index={index}
                                                          active={this.state.active} {...this.props}></DropDown>
                                            ) || ''
                                        }
                                    </Link>);
                                }
                                return nav !== 0 ? tga : '';
                            })
                        }
                    </div>
                    <a href="https://www.djcps.com/" className="ent" target="_blank">进入平台</a>
                    <div className="header-menu-box">
                        <Menu isWhiteTheme={isWhiteTheme} onShow={this.changeMenuState.bind(this, true)} onHide={this.changeMenuState.bind(this, false)}></Menu>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
