
import React, { PureComponent as Component } from "react";
import $ from 'jquery';
import css from "./index.module.scss";
import {getTransition, throttle} from "../../../utils/methods";
class Slider extends Component {
  constructor(props) {
    super(props);
    this.wrap = React.createRef();
    this.el = React.createRef();
    this.transition = getTransition();
  }

  // 拖拽效果
  drag = (e) => {
    let historyMain = $(this.wrap.current)
    e.preventDefault();
    var distenceX = e.pageX;
    var startX = historyMain[0].style.left.split('px')[0] * 1;
    this.updateSpeed(distenceX);
    const move = throttle(20,(e) => {
      this.updateSpeed(e.pageX);
      this.move(startX + (e.pageX - distenceX));
    })
    $(document).on('mousemove', move);
    $(document).one('mouseup', () => {
      $(document).off('mousemove', move);
      this.inertia(this.updateSpeed());
    });
  }

  updateSpeed = (() => {
    let last;
    let time;
    let speed = 0;
    return (x) => {
      if (x === undefined) return speed;
      let _time = new Date().getTime();
      if (last)  speed = (x - last) / (_time - time);
      last = x;
      time = _time;
      return speed;
    }
  })()

  // 惯性运动
  inertia = (()=>{
    let timer;
    return (speed) => {
      if (!speed) return;
      let bool = speed > 0;
      clearInterval(timer);
      timer = setInterval(()=>{
        let left = $(this.wrap.current).css('left').split('p')[0] * 1;
        this.move(left + speed * 20);
        speed += bool ? -0.2 : 0.2;
        if ((bool && speed <= 0) || (!bool && speed >= 0)) {
          clearInterval(timer);
        }
      }, 20)
    }
  })();

  move = (x) => {
    let {onMove, endPos = 'end'} = this.props;
    let el = $(this.el.current)
    let historyMain = $(this.wrap.current)
    if(x > 0) x = 0;
    let max;
    if (endPos === 'start') {
      max = historyMain[0].scrollWidth;
    } else {
      max = historyMain[0].scrollWidth - el.outerWidth();
    }
    if(x < -max ) x = -max;
    historyMain.css({left: x + 'px'});
    this.checkIndexChange();
  }

  checkIndexChange = () => {
    let historyContent = $(this.el.current)
    let historyMainBox = $(this.wrap.current).find('> div')
    var minX = {number:999999,object:null, index: 0};
    historyMainBox.each(function (i){
      let number = Math.abs( $(this).offset().left - historyContent.offset().left);
      if(minX.number > number){
        minX.number = number;
        minX.object = this;
        minX.index = i;
      }
    });
    this.props.onMove && this.props.onMove(minX);
  }

  goByIndex = (index, offset = 0) => {
    let context = $(this.el.current)
    let currentLeft = parseFloat($(this.wrap.current).css('left'));
    let item = $(this.wrap.current).find('> div')[index];
    let standard = context.offset().left;
    let {left} = item.getBoundingClientRect();
    let end = currentLeft - (left - standard) + offset;
    if (this.props.isTransition) {
      this.move(end);
    } else {
      this.transition(currentLeft, end, this.move, 200);
    }
  }

  go = (bool) => {
    let width = $(this.el.current).outerWidth();
    let left = $(this.wrap.current).css('left').split('p')[0] * 1;
    let end = left + (bool ? -width : width);
    if (this.props.isTransition) {
      this.move(end);
    } else {
      this.transition(left, left + (bool ? -width : width), this.move);
    }
  }

  pre = () => {
    this.go();
    this.props.onPrev && this.props.onPrev();
  }

  nex = () => {
    this.go(true);
    this.props.onNext && this.props.onNext();
  }

  render() {
    let {children, width, noBtn, isTransition} = this.props;
    return (
      <div ref={this.el} className={`${css["slider"]} clearfix ${isTransition ? css['is-transition'] : ''}`} style={{width}}>
        <div className={`${css["slider-wrap"]} clearfix`} ref={this.wrap} onMouseDown={this.drag}>
          {children}
        </div>
        {
          noBtn ? '' : (
            <div className={css["pre"]} onClick={this.pre}>
              <div className={css["ico"]}> </div>
            </div>
          )
        }
        {
          noBtn ? '' : (
            <div className={css["nex"]} onClick={this.nex}>
              <div className={css["ico"]}></div>
            </div>
          )
        }
      </div>
    )
  }
}
export default Slider;
