/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-17 21:53:18
 * @LastEditTime: 2021-02-18 13:24:46
 * @LastEditors: dingxuejin
 */
import React, {PureComponent as Component} from "react";
import './index.scss';
import PropTypes from 'prop-types';
import Slider from "../../../../../components/common/Slider";

class Panel extends Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.state = {};
  }

  goByIndex = (index) => {
    this.slider.current.goByIndex(index);
  }

  getContent = (panelData) => {
    return (
      <div className="itm">
        <div className="top">
          <div className="des">{panelData.des}</div>
          <div></div>
        </div>
        <div className="fix">
          {panelData.fix}
        </div>
        <img className="app_img" src={panelData.rig} alt=""/>
        <div className="bot">
          <div className="lin"></div>
          <p className="text1">{panelData.text1}</p>
          {/*<p className="text2">{this.props.panelData.text2}</p>*/}
        </div>
      </div>
    )
  }

  // onChange = (...argv) => {
  //   this.props.onChange && this.props.onChange(...argv);
  // }

  render() {
    let {allPanelData = []} = this.props;
    return (
      <div className="dj-home-busi-con_slider">
        <div className="lef">
          <Slider ref={this.slider} onPrev={this.props.onPrev} onNext={this.props.onNext} isTransition={true}>
            {allPanelData.map(item => this.getContent(item.panelData))}
          </Slider>
          <a className="app_more-btn" target="_blank" href={this.props.to + "?source=siteProductSuggest"}>查看更多</a>
        </div>
      </div>
    );
  }
}

Panel.propTypes = {
  panelData: PropTypes.object,
  allPanelData: PropTypes.array,
};

export default Panel;
