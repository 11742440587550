/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-16 17:05:40
 * @LastEditTime: 2021-02-20 18:40:35
 * @LastEditors: dingxuejin
 */

import { PureComponent as Component } from "react";
import css from './index.module.scss';

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errText: "",
            validateAll: this.props.validateAll,
            value: ""
        };
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    change(e, lable) {
        let val = {
            value: e.target.value,
            key: lable
        }
        if (e.target.value) {
            this.setState({
                error: false,
                errText: ""
            })
        }
        this.props.InputChange(val);
    }
    onValidate = (e) => {
        let value = e.target.value;

        this.setState({
            value: value
        })
        this.validate(value);
    }
    validate = (value) => {
        let rule = this.props.rule;
        for (let index = 0; index < rule.length; index++) {
            let item = rule[index];
            if (item.type == "require") {
                if (!value) {
                    this.setState({
                        error: true,
                        errText: item.msg
                    })
                    break
                }
            }
            if (item.type == "custom") {
                let f = item.validate(value);
                if (!f) {
                    this.setState({
                        error: true,
                        errText: item.msg
                    })
                    break
                }
            }
        }
    }

    reset = () => {
        this.setState({
            error: false,
            errText: "",
            value: ""
        });
    }

    render() {
        return (
            <div className={css["input"]}>
                <label className={css["label"]}>{this.props.label}</label>
                <input {...this.props} type="text" style={{ "width": this.props.width + "px" }} onInput={(e) => this.change(e, this.props.name)} className={this.state.error ? css["boder_err"] : ""} onBlur={this.onValidate} />
                {this.state.error ? (< div className={css["err"]}>
                    {this.state.errText}
                </div>) : ""}
            </div>
        );
    }
}

export default Input;