import {PureComponent} from 'react';
import css from './index.module.scss'
import arrowIcon from '../../../../assets/images/news/icon_btn_right_blu.svg'
import arrowIcon_hover from '../../../../assets/images/news/icon_btn_right_wht.svg'

class DetailBtn2 extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className={css['detail-btn']} onClick={this.props.onClick}>
          <span className={css['detail-btn-txt']}>查看详情</span>
          <span className={css['detail-btn-icon']}>
            <img className={css['normal']} src={arrowIcon}/>
            <img className={css['hover']} src={arrowIcon_hover}/>
          </span>
        </div>
    );
  }
}

export default DetailBtn2;