/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-15 20:41:35
 * @LastEditTime: 2021-02-21 14:09:37
 * @LastEditors: dingxuejin
 */

import { lazy, PureComponent as Component } from "react";
import css from './index.module.scss';
import enums from '../../../utils/enum';
import joinService from '@/api/service/join';

const CheckGroup = lazy(() => import("./components/checkGroup"));


const Input = lazy(() => import("./components/input/index"));
const Refrash = lazy(() => import("./components/refrash/index"));
const Msg = lazy(() => import("./components/msg"));


class Join extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                check: [],
                username: "",
                phone: "",
                company: "",
                code: ""
            },
            checkOption: JSON.parse(JSON.stringify(enums.intentionType._arr)),
            menu: [
                {
                    label: "姓名",
                    name: "username",
                    rule: [{ type: "require", msg: "请输入姓名", trigger: 'blur' }],
                    attrs: {
                        maxLength: 20
                    }
                },
                {
                    label: "手机号",
                    name: "phone",
                    rule: [{ type: "require", msg: "请输入手机号", trigger: 'blur' }, {
                        type: "custom", msg: "手机号格式错误", trigger: 'blur', validate: this.checkPhone
                    }],
                },
                {
                    label: "公司名称",
                    name: "company",
                    rule: [{ type: "require", msg: "请输入公司名称", trigger: 'blur' }],
                    attrs: {
                        maxLength: 20
                    }
                }
            ],
            child: [],
            checkChild: "",
            currenCode: "",
            refrashChild: "",
            msgInfo: ""
        };
    }

    componentWillMount() {

    }

    //手机号校验
    checkPhone = (val) => {
        let valid_rule = /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/;// 手机号码
        if (!valid_rule.test(val)) {
            return false;
        }
        return true;
    }

    //验证码校验
    checkCode = (val) => {
        let valid_rule = /^[\d|a-zA-Z]{4}$/;// 验证码
        if (!valid_rule.test(val)) {
            return false;
        }
        if (!new RegExp(`^${this.state.currenCode || ''}$`, 'i').test(val)) {
            return false;
        }
        return true;
    }

    onchangeCheck = (val) => {
        this.setState(state => ({
            form: {
                ...state.form,
                check: val,
            }
        }))
    }
    onInputChang = (val) => {
        let { value, key } = val;
        let form = { ...this.state.form };
        form[key] = value;
        this.setState({
            form: form
        })
    }

    Submit = () => {
        console.log(this.state.form)
        let status = [];
        //校验input
        this.state.child.map((item) => {
            item.validate(item.state.value)
            setTimeout(() => {
                status.push(!item.state.error)
            })
        })
        //校验checkbox
        if (this.state.form.check.length == 0) {
            this.state.checkChild.change()
            return;
        }
        setTimeout(() => {
            console.log(status);
            let f = status.every((item => {
                return item
            }))

            if (f) {
                let { check, username, phone, company } = this.state.form;
                //调用后台
                let data = {
                    ftype: check.join(','),
                    fusername: username,
                    fphone: phone,
                    fcompany: company
                }
                joinService.insertApply(data).then((res) => {
                    this.msg({
                        type: "success",
                        text: '提交成功'
                    });
                    this.Reset();
                });
            }
        })
    }
    msg = (msg) => {
        this.setState({
            showMsg: true,
            msgInfo: msg
        })
        setTimeout(() => {
            this.onChangeMsg();
        }, 2000);
    }
    Reset = () => {
        document.getElementById("myform").reset();
        this.setState({
            form: {
                check: [],
                username: "",
                phone: "",
                company: "",
                code: ""
            },
            // checkOption: JSON.parse(JSON.stringify(enums.intentionType._arr)),
        })
        this.state.child.map((item) => {
            item.reset();
        })
        this.state.checkChild.reset()
        this.state.refrashChild.refrash()
    }

    onRef = (ref) => {
        this.state.child.push(ref)
    }
    onRefCheck = (ref) => {
        this.setState({
            checkChild: ref
        })
    }
    onRefRefrash = (ref) => {
        this.setState({
            refrashChild: ref
        })
    }
    onCode = (val) => {
        this.setState({
            currenCode: val
        })
    }
    onChangeMsg = () => {
        this.setState({
            msgInfo: ""
        })
    }
    render() {
        return (
            <div className={css["djjoin"]} >
                <Msg msgInfo={this.state.msgInfo} onChangeMsg={this.onChangeMsg}></Msg>
                <div className={css["con"]}>
                    <div className={css["text"]}>联系我们 成为伙伴</div>
                    <div className={css["warp"]}>
                        <form className={css["form"]} id="myform">
                            <div className={css["itm"]}>
                                <label className={css["label"]}>我想(可多选)</label>
                                <CheckGroup checkOption={this.state.checkOption} onchangeCheck={this.onchangeCheck} onRef={this.onRefCheck}></CheckGroup>
                            </div>
                            {
                                this.state.menu.map((item, index) => {

                                    return (<div className={css["itm"]} key={index}>
                                        <Input placeholder={`请输入${item.label}`} label={item.label} name={item.name} InputChange={this.onInputChang} rule={item.rule} validateAll={this.state.validateAll} onRef={this.onRef} {...item.attrs || {}}></Input>
                                    </div>)
                                })
                            }
                            <div className={css["itm"]}>
                                <Input placeholder="请输入验证码" label="验证码" name="code" width={190} InputChange={this.onInputChang}
                                       rule={[
                                         { type: "require", msg: "请输入验证码", trigger: 'blur' },
                                         { type: "custom", msg: "验证码错误", trigger: 'blur', validate: this.checkCode }
                                       ]}
                                       validateAll={this.state.validateAll} onRef={this.onRef}></Input>
                                <div className={css["ref"]}>
                                    <Refrash onCode={this.onCode} onRef={this.onRefRefrash}></Refrash>
                                </div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["btn"]}>
                                    <div className={css["sub"]} onClick={this.Submit}>提交</div>
                                    <div className={css["res"]} onClick={this.Reset}>重置</div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </ div >
        );
    }
}

export default Join;