import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import css from './index.module.scss'

class Ellipse extends PureComponent {
  constructor() {
    super();
  }

  static propTypes = {
    // 文本最大长度
    max: PropTypes.number,
    children: PropTypes.string,
  }

  render() {
    let {max, children = ''} = this.props;
    let isSlice = max && children && children.length > max;
    return (
        <span className={css['ellipse']} title={children}>
          {children && children.slice(0, max)}{isSlice ? '...' : ''}
        </span>
    );
  }
}

export default Ellipse;