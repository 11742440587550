//兼容react在ie9,10报map和set错误
import 'core-js/es/map';
import 'core-js/es/set';
import { Provider, connect } from "react-redux";
//引入rem
import 'dc-rem';

import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.scss';
// import './dc-scale';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store";

ReactDOM.render(
  <Provider store={store}><App /></Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
