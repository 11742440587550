/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-18 13:29:14
 * @LastEditTime: 2021-02-20 11:41:33
 * @LastEditors: dingxuejin
 */
import { lazy, PureComponent as Component } from "react";
import { connect } from "react-redux";
import css from './index.module.scss';
import {throttle} from '../../../../utils/methods'
import {changeHeaderScrollShield} from '../../../../store'
const Item = lazy(() => import("@/pages/product/components/item/index"));

@connect(null, {changeHeaderScrollShield})
class tapBar extends Component {
    constructor(props) {
        super(props);
        this.timmer = null;
        this.state = {
            isFixed: false,
            nav: this.props.navData,
            active: {}
        }
    };
    componentDidMount() {
        setTimeout(() => {
            var obj = document.getElementById('tapBar');
            this.activeJudge();
        })

        window.addEventListener('scroll', this.listenScro)

        this.scroll(this.props.history.location, true);
        this.props.history.listen(this.scroll)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenScro);
    }

    scroll = (route, bool) => {
        if (route.pathname == "/about") {
            let id = route.search.replace("?", "")
            if (id) {
                setTimeout(() => {
                    this.to(id, bool)
                })
            }
        }
    }

    listenScro = throttle(20,() => {
        this.fixedJudge();
        this.activeJudge();
    })

    // 激活逻辑
    activeJudge = () => {
        let top,item,nav = this.state.nav;
        nav.map(obj => {
            let el = document.getElementById(obj.name);
            let _top = el.getBoundingClientRect().top;
            if (_top < 0) return;
            if (!top || _top < top) {
                top = _top;
                item = obj;
            }
        });
        if (!item) {
            item = nav[nav.length - 1];
        }
        this.setState({
            active: item
        })
    }

    // 悬浮逻辑
    fixedJudge = () => {
        let wrap = document.querySelector('.' + css['tapbar']);
        let pos = wrap.getBoundingClientRect();
        this.setState({
            isFixed: pos.top < 0
        })
    }

    to = (id, bool) => {
        let obj = document.getElementById(id);
        if (obj) {
            obj.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            if (!bool) {
                // 这里需要让header停止滚动监听，否则向上滚时，header会悬浮挡住页面内部的tapbar
                this.props.changeHeaderScrollShield(true);
                clearTimeout(this.timmer);
                // 硬编码，时间紧迫目前先这样应对
                this.timmer = setTimeout(()=>{
                    this.props.changeHeaderScrollShield(false)
                }, 1000)
            }
        }
    }
    render() {
        let {active} = this.state;
        return (
            <Item className={css['tapbar']}>
                <div className={this.state.isFixed ? css["con"] + " " + css["fixed"] : css["con"]} id="tapBar">
                    <ul className={css["lst"]}>
                        {
                            this.state.nav.map((item, index) => {
                                return (<li className={`${css["itm"]} ${active === item ? css['active'] : ''}`} onClick={() => this.to(item.name)} key={index}>{item.description}</li>)
                            })
                        }
                    </ul >
                </div>
            </Item >
        )
    }
}

export default tapBar;
