
/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-18 13:21:45
 * @LastEditors: dingxuejin
 */
import { lazy, PureComponent as Component } from "react";
import { connect } from "react-redux";
import './index.scss';
// import mp4 from "@/assets/images/intro.mp4";
// import bg from "@/assets/images/header_bg.mp4";
// const Video = lazy(() => import("./components/video"));
const Video = lazy(() => import("../Video"));
const AppFirstScreen = lazy(() => import("./components/appFirstScreen"));

@connect(state => ({isMobile: state.getIn(['app', 'isMobile'])}),)
class Display extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowVideo: false,
            bgImage: "",
            description: "",
            details: ""
        }
        if (this.props.route) {
            let { bgImage, description, details } = this.props.route
            this.state =
                Object.assign(this.state, { bgImage, description, details })
        }

    }

    clickBtn = () => {
        this.setState({
            isShowVideo: true
        })
    }
    closeVideo = () => {
        this.setState({
            isShowVideo: false
        })
    }
    render () {
        return (
            <div>
                {this.props.layout == 1 ?
                    (<section className="dj-vedio">
                        {
                            this.state.isShowVideo ? <Video src={"https://djcps.oss-cn-hangzhou.aliyuncs.com/web/djwebsite/intro.mp4"} onClose={this.closeVideo} oAttrs={{ autoPlay: true, muted: true, loop: true }}></Video> : ""
                        }
                        {
                            this.props.isMobile ? (
                              <AppFirstScreen onPlay={this.clickBtn}></AppFirstScreen>
                            ) : (
                              <>
                              <video className="dj-vedio-bg" src={"https://djcps.oss-cn-hangzhou.aliyuncs.com/web/djwebsite/header_bg.mp4"} autoPlay={true} loop={true} preload="true" muted={true}></video>
                              <div className="dj-vedio-warp">
                                  <div className="dj-vedio-title"></div>
                                  <div className="dj-vedio-describe">打造中小企业降本提效、价值倍增的包装供应链服务平台</div>
                                  <div className="dj-vedio-btn" onClick={this.clickBtn}></div>
                                  <div className="dj-vedio-line">
                                      <i className="s_line"></i>
                                      <div className="txt">滑动</div>
                                  </div>
                              </div>
                              </>
                            )
                        }
                    </section>)
                    :
                    this.props.layout == 2 ?
                        (<section className="dj-display" style={{
                            backgroundImage: `url(${this.state.bgImage})`
                        }}>
                            <div className="dj-display-warp">
                                <div className="dj-display-title">{this.state.description}</div>
                                <div className="dj-display-describe">{this.state.details}</div>
                            </div>
                        </section>)
                        : ""
                }
            </div >
        );
    }
}

export default Display;

