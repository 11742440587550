import {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import css from './index.module.scss'
import {checkType} from '../../../../utils/methods'

class Block extends PureComponent {
  constructor() {
    super();
  }

  static defaultProps = {
    noMore: false,
    theme: 'normal'
  }

  static propTypes = {
    // 是否展示'更多'按钮
    noMore: PropTypes.bool,
    // 查看按钮跳转地址
    link: PropTypes.string,
    // 标题
    title: PropTypes.node,
    // 主题,可以当类名用
    theme: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    children: PropTypes.node,
  }

  render() {
    let { noMore, link, title, children, theme } = this.props;
    if (checkType(theme, 'String')) theme = [theme];
    return (
        <div className={`${css['block']} ${theme.map(str=>css[str]).join(' ')}`}>
          <div className={css['block-header']}>
            <h1 className={css['block-header-title']}>{title}</h1>
            <div className={css['block-header-more']}>
              {
                !noMore && <Link to={link}>查看全部 ></Link>
              }
            </div>
          </div>
          <div className={'block-content'}>
            {children}
          </div>
        </div>
    );
  }
}

export default Block;