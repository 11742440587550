/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-17 21:53:18
 * @LastEditTime: 2021-02-18 13:24:46
 * @LastEditors: dingxuejin
 */
import { PureComponent as Component } from "react";
import { Link } from "react-router-dom";
import './index.scss';
import PropTypes from 'prop-types';
import SlideRight from "../../../../../components/animation/SlideRight";
import SlideLeft from "../../../../../components/animation/SlideLeft";

class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="dj-home-busi-con">
                <SlideRight>
                    <div className="lef">
                        <div className="top">
                            <div className="des">{this.props.panelData.des}</div>
                            <div></div>
                        </div>
                        <div className="fix">
                            {this.props.panelData.fix}
                        </div>
                        <img className="app_img" src={this.props.panelData.rig} alt=""/>
                        <div className="bot">
                            <div className="lin"></div>
                            <p className="text1">{this.props.panelData.text1}</p>
                            {/*<p className="text2">{this.props.panelData.text2}</p>*/}
                        </div>
                        <a className="all" target = "_blank" href={this.props.to+"?source=siteProductSuggest"}>查看更多＞</a>
                        <a className="app_more-btn" target = "_blank" href={this.props.to+"?source=siteProductSuggest"}>查看更多</a>
                    </div>
                </SlideRight>
                <div className="rig">
                    <SlideLeft>
                        <img src={this.props.panelData.rig} alt=""/>
                    </SlideLeft>
                </div>
            </div >
        );
    }
}

Panel.propTypes = {
    panelData: PropTypes.object
};

export default Panel;
