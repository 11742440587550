/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-15 20:41:35
 * @LastEditTime: 2021-02-21 16:56:58
 * @LastEditors: dingxuejin
 */

import { lazy, PureComponent as Component } from "react";
import css from './index.module.scss';
import contact_iocn_apply from '../../../assets/images/contact/contact_iocn_apply.svg'
const Concat = lazy(() => import("@/pages/product/djCardboard/concat"));
const Item = lazy(() => import("@/pages/product/components/item/index"));
const TapBar = lazy(() => import("@/pages/about/components/tapBar"));

const NAV = [
      {
          active: true,
          title: "行业走势",
          activ_icon: require(`@/assets/images/contact/contact_iocn_sel_zoushi.svg`).default,
          bgImage: require(`@/assets/images/contact/contact_bg_zoushi.jpg`).default,
          text: [
              "根据中国包装联合会发布的统计数据显示：近10多年来我国包装工业规模持续增长，2016年发布的《关于加快我国包装产业转型发展的指导意见》提出，到2020年包装产业年均主营业务收入将达到2.5万亿元，形成15家以上年产值超过50亿元的企业。",
              '随着政府对“两化融合”“集约化”大力扶持，未来将是产业互联网时代。'
          ]
      },
      {
          active: false,
          title: "市场潜力",
          activ_icon: require(`@/assets/images/contact/contact_iocn_hover_shichang.svg`).default,
          bgImage: require(`@/assets/images/contact/contact_bg_qianli.jpg`).default,
          text: ["随着整个国际、国内市场对包装产品环保性要求的日益提高，纸类包装材料成为很多行业首选的包装材料。据统计，我国人均瓦楞纸板消费仅为40平方米/年，远低于美国、日本等发达国家人均100平方米以上/年的水平，未来仍存在较大的提升空间"]
      },
      {
          active: false,
          title: "行业痛点",
          activ_icon: require(`@/assets/images/contact/contact_iocn_sel_tongdian.svg`).default,
          bgImage: require(`@/assets/images/contact/contact_bg_tongdian.jpg`).default,
          text: ["原纸和纸板成本飙升，价格持续暴涨；环保、消防、税务成本快速提高，人工、水电、租金成本逐年上涨，中小性企业销售利润被压缩，面临生存与发展双重困境。"]
      },
      {
          active: false,
          title: "行业竞争",
          activ_icon: require(`@/assets/images/contact/contact_iocn_sel_jingzheng.svg`).default,
          bgImage: require(`@/assets/images/contact/contact_bg_jingzheng.jpg`).default,
          text: ["随着“两化融合”走进更多的企业，信息化，数据化时代已经来临，更多的产业开始“集约化”，集中优势资源，产生规模化交易，对于产业龙头公司而言，这是一 片蓝海，对中小企业却是发展困境。"]
      }
  ];

class Channel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TapData: [
                {
                    path: "?market",
                    description: "市场现状",
                    name: "market"
                },
                {
                    path: "?industry",
                    description: "行业优势",
                    name: "industry"
                },
                {
                    path: "?coop",
                    description: "合作支持",
                    name: "coop"
                },
                {
                    path: "?partner",
                    description: "伙伴类型",
                    name: "partner"
                },
                {
                    path: "?application",
                    description: "申请流程",
                    name: "application"
                }
            ],
            nav: NAV,
            panelData: NAV[0],
        }
    }

    change(e, index) {
        let nav = [...this.state.nav];

        nav.forEach((item, i) => {
            if (index == i) {
                item.active = true
            } else {
                item.active = false
            }
        })
        this.setState({
            nav: nav,
            panelData: nav[index],
        })
    }

    render() {
        return (
            <div className={css["djchannel"]}>
                <TapBar {...this.props} navData={this.state.TapData}></TapBar>
                <Item title="市场现状">
                    <div className={css["con"]} id="market">
                        <div className={css["lef"]} >
                            {this.state.nav.map((item, index) => {
                                return (
                                    <div className={item.active ? css["itm"] + " " + css["active"] : css["itm"]} key={index} onClick={(e) => this.change(e, index)}>
                                        <div className={css["war"]}>
                                            <div className={css["ico"]} style={{ backgroundImage: item.active ? `url(${item.activ_icon})` : "" }}></div>
                                            <div className={css["tex"]}>{item.title}</div>
                                        </div>
                                    </div>)
                            })}
                        </div>
                        <div className={css["rig"]}>
                            <div className={css["inf"]}>
                                <div className={css["titl"]}>{this.state.panelData.title}</div>
                                <div className={css["dec"]}>
                                    {this.state.panelData.text.map(str=>(<p>{str}</p>))}
                                </div>
                            </div>
                        </div>
                        <div className={css["bg"]} style={{
                            backgroundImage: `url(${this.state.panelData.bgImage})`
                        }}></div>
                    </div>
                </Item>
                <Item title="东经优势">
                    <div className={css["con"]} id="industry">
                        <div className={css["des"]}>行业痛点突出，万亿市场蓝海，东经与你一起建立共赢发展模式</div>
                        <div className={css["top"]}>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>20余年行业沉淀</div>
                                <div className={css["des"]}>东经凭借26年包装行业全流程管理经验的沉淀，投入上亿元资金及100多人的研发团队，打造出了包装行业数字化、智能化的供应链服务平台。</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>深受客户信赖</div>
                                <div className={css["des"]}>东经平台上线以来，已累计服务超过20000+家纸包装行业企业，4大产品深受纸板、纸箱厂的青睐</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>囊获众多奖项和荣誉</div>
                                <div className={css["des"]}>专业赋能能力，获得中包联、工业和信息化部一致认可，多次获得工业、产业互联网相关奖项，为东经背书</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>强大的人才技术优势</div>
                                <div className={css["des"]}>东经技术核心成员均来自阿里、腾讯、华为等知名科技公司；核心赋能团队来自于国内顶尖的纸包装上市企业；既精通技术，还深谙背后的商业逻辑</div>
                            </div>
                        </div>
                        <div className={css["bot"]}>
                            <div className={css["itm"]}>
                                <div className={css["img"]}></div>
                                <div className={css["info"]}>
                                    <div className={css["titl"]}>产品稳步增值</div>
                                    <div className={css["des"]}>东经科技将根据市场变化，不断梳理上下游企业痛点，不定期更新产品，紧跟工业互联网变革浪潮，最大程度降低合作伙伴的投资风险</div>
                                </div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["img"]}></div>
                                <div className={css["info"]}>
                                    <div className={css["titl"]}>高回报率</div>
                                    <div className={css["des"]}>优厚的收益成分，保证合作伙伴的利润空间。</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
                <Item title="合作支持">
                    <div className={css["con"]} id="coop">
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>用户资源</div>
                            <div className={css["des"]}>共享客户资源，商机源源不断</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>多维市场扶持政策</div>
                            <div className={css["des"]}>产品技术、服务、市场多维助推，满足合作商的市场需求</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>全方位培训服务</div>
                            <div className={css["des"]}>不定期提供纸包装企业数字化升级、互联网技术、行业最佳实践案例等培训服务</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["titl"]}>专业运营策略指导</div>
                            <div className={css["des"]}>提供所涉行业的市场战略分析，协助合作伙伴不定时优化市场策略</div>
                        </div>
                    </div>
                </Item>
                <Item title="伙伴类型">
                    <div className={css["con"]} id="partner">
                        <div className={css["itm"]}>
                            <div className={css["titl"]}>代理产品伙伴</div>
                            <div className={css["des"]}>在纸包装行业拥有良好的社会关系，具备一定的销售能力，以代理商名义进行产品销售工作</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["titl"]}>商机共享伙伴</div>
                            <div className={css["des"]}>欢迎有良好社会关系的个人、团体成员称为东经科技的商机共享伙伴，提供优质商机，搭建沟通桥梁，促进合作达成</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["titl"]}>产品合作伙伴</div>
                            <div className={css["des"]}>来自数字化解决方案、ERP、WMS等系统软件公司，具备接口开发能力的公司，应用软件定制开发 的公司共同交流技术，对接数据，优化产品，以求为企业提供更全面的解决方案</div>
                        </div>
                    </div>
                </Item>
                <Item title="申请流程">
                    <div className={css["con"]} id="application">
                        <div className={css["itm"]}>
                            <div className={css["titl"]}>01</div>
                            <div className={css["des"]}>申请加盟</div>
                        </div>
                        <span className={css["divide"]}><img src={contact_iocn_apply} alt=""/></span>
                        <div className={css["itm"]}>
                            <div className={css["titl"]}>02</div>
                            <div className={css["des"]}>合作洽谈</div>
                        </div>
                        <span className={css["divide"]}><img src={contact_iocn_apply} alt=""/></span>
                        <div className={css["itm"]}>
                            <div className={css["titl"]}>03</div>
                            <div className={css["des"]}>签署协议</div>
                        </div>
                        <span className={css["divide"]}><img src={contact_iocn_apply} alt=""/></span>
                        <div className={css["itm"]}>
                            <div className={css["titl"]}>04</div>
                            <div className={css["des"]}>内部培训</div>
                        </div>
                        <span className={css["divide"]}><img src={contact_iocn_apply} alt=""/></span>
                        <div className={css["itm"]}>
                            <div className={css["titl"]}>05</div>
                            <div className={css["des"]}>持续盈利</div>
                        </div>
                    </div>
                </Item>
                <Item>
                    <Concat {...this.props}>联系我们，成为伙伴</Concat>
                </Item>
            </div >
        );
    }
}

export default Channel;