import css from './index.module.scss'
import story_icon from '@/assets/images/case/story_icon.svg';

function Question(props) {
  let {que, children} = props;
  return (
      <div className={css['question']}>
        <div className={css['question-que']}>
          <img className={css['question-que-img']} src={story_icon} alt=""/>
          <span className={css['question-que-txt']}>{que}</span>
        </div>
        <div className={css['question-text']}>
          <pre>
            {children}
          </pre>
        </div>
      </div>
  );
}

export default Question;