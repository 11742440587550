
/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-05 21:25:33
 * @LastEditTime: 2021-02-21 16:51:09
 * @LastEditors: dingxuejin
 */
import React, {lazy, PureComponent as Component} from "react";
import './index.scss'
import { Link } from "react-router-dom";
import infoService from '@/api/service/info'
import {Spin} from "antd";
import SwiperItem from "../../../news";
import css from "../../../news/index.module.scss";
import enums from "../../../../utils/enum";
import {parseTime} from "../../../../utils/methods";
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux';
import {setOssUrl} from "@/utils/methods/_common"
// import Swiper from "../../../news/components/Swiper";
import Slider from "../../../../components/common/Slider";
// import Swiper from "../../../../components/common/swiper";
const Block = lazy(() => import("../../../news/components/Block"));
const ItemGroup = lazy(() => import("../../../news/components/ItemGroup"));
const Item = lazy(() => import("../../../news/components/Item"));
const SlideUp = lazy(() => import("../../../../components/animation/SlideUp"));

function handleData(list) {
    return (list || []).map(item => ({
        id: item.fid,
        title: item.ftitle,
        img: setOssUrl(item.fmainpic),
        time: parseTime(item.fsubmittime, 'YYYY/MM/DD'),
        content: item.fabstract,
        videoUrl: setOssUrl(item.fvideo),
    }))
}

@connect(state => ({isMobile: state.getIn(['app', 'isMobile'])}),)
class Dynamic extends Component {
    constructor(props) {
        super(props);
        this.slider = React.createRef();
        this.state = {
            list: [],
            isLoading: false,
            activeIndex: 0,
        };
    }
    componentDidMount() {
        this.getList();
    }

    getList = () => {
        this.setState({isLoading: true});
        infoService.dynamicList({pageNo: 1, pageSize: 3}).then(res => {
            this.setState({
                list: handleData(res.list)
            });
        }).finally(()=>{
            this.setState({isLoading: false});
        });
    }

    goDetailsPage = (item, type) => {
        this.props.history.push(`/news/details/${type}/${item.id}`);
    }

    onItemChange = (index) => {
        let {list} = this.state;
        if (index < 0) index += list.length;
        if (index > list.length - 1) index -= list.length;
        this.setState({activeIndex: index});
        this.slider.current.goByIndex(index);
    }

    render() {
        let {list, activeIndex} = this.state;
        let {isMobile} = this.props;
        return (
            <div className="dj-home-dynamic" id="dyn">
                <Block noMore={isMobile} title="企业动态" link="/news/enterpriseNews" theme={['more-center', 'title-left']}>
                    <div className="content">
                        <Spin spinning={this.state.isLoading}>
                            {
                                list.length && isMobile ? (
                                  <Slider ref={this.slider} onPrev={() => this.onItemChange(--activeIndex)}
                                          isTransition={true}
                                          onNext={() => this.onItemChange(++activeIndex)}>
                                      {list.map((item, index) => (
                                        <div className="itm-box">
                                            <Item key={index} item={item} onClick={()=>this.goDetailsPage(item, enums.blocks.dynamic.value)}></Item>
                                        </div>
                                      ))}
                                  </Slider>
                                ) : (
                                  <ItemGroup layout={[[32, 32, 32]]}>
                                      {list.map((item, index) => (
                                        <SlideUp key={index} delay={index * 200}>
                                            <Item key={index} item={item} onClick={()=>this.goDetailsPage(item, enums.blocks.dynamic.value)}></Item>
                                        </SlideUp>
                                      ))}
                                  </ItemGroup>
                                ) || ''
                            }
                        </Spin>
                        <div className="app_more-btn-box">
                            <Link className="app_more-btn" to="/news/enterpriseNews">查看更多</Link>
                        </div>
                    </div>
                </Block>
            </div>
        );
    }

    // render() {
    //     return (
    //         <div className="dj-home-dynamic" id="dyn">
    //             <div className="dj-home-dynamic-titl">
    //                 <div className="dj-home-dynamic-titl-mai">企业动态</div>
    //                 <Link to="/news/enterpriseNews" className="dj-home-dynamic-titl-mor">查看更多＞</Link>
    //             </div>
    //             <div className="dj-home-dynamic-lit">
    //                 {
    //                     this.state.list.map((item, index) => {
    //                         return (<div className="dj-home-dynamic-lit-itm" key={index}>
    //                             <div className="dj-home-dynamic-lit-itm-img" style={{ backgroundImage: `url(${item.fmainpic})` }}></div>
    //                             <div className="dj-home-dynamic-lit-itm-con">
    //                                 <div className="dj-home-dynamic-lit-itm-dec">{item.ftitle}</div>
    //                                 <div className="dj-home-dynamic-lit-itm-tet">{item.fabstract}</div>
    //                             </div>
    //                         </div>)
    //                     })
    //                 }
    //
    //
    //             </div>
    //         </div >
    //     );
    // }
}

export default withRouter(Dynamic);
