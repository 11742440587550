import {PureComponent} from 'react';
import DetailBtn from '../DetailBtn';
import ItemGroup from '../ItemGroup';
import Ellipse from '../Ellipse';
import Swiper from '../Swiper';
// import { Carousel } from 'antd';
import css from './index.module.scss'

class SwiperItem extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      active: props.list[0]
    }
  }

  render() {
    let item = this.state.active || {};
    let list = this.props.list || [];
    let onDetail = this.props.onDetail;
    let className = this.props.className;
    return (
        <ItemGroup className={className} layout={[[66, 34]]}>
          <div className={css['swiper-item-swiper']}>
            {/*<Carousel afterChange={this.onChange}>*/}
              {/*{*/}
                {/*list.map(item=>(*/}
                    {/*<img height={'100%'} height={'466'} src={item.img} alt=""/>*/}
                {/*))*/}
              {/*}*/}
            {/*</Carousel>*/}
            <Swiper afterChange={this.onChange}>
              {
                list.map((item, index)=>(
                    <img key={index} className={css['swiper-item-img']} src={item.img} alt=""/>
                ))
              }
            </Swiper>
          </div>
          <div className={css['swiper-item-box']}>
            <p className={css['swiper-item-time']}>{item.time}</p>
            <p className={css['swiper-item-title']}><Ellipse max={33}>{item.title}</Ellipse></p>
            <p className={css['swiper-item-content']}><Ellipse max={105}>{item.content}</Ellipse></p>
            <div className={css['swiper-item-btn']}>
              <DetailBtn onClick={()=>onDetail && onDetail(item)}></DetailBtn>
            </div>
          </div>
        </ItemGroup>
    );
  }

  onChange = (cur) => {
    this.setState({
      active: this.props.list[cur]
    });
  }
}

export default SwiperItem;