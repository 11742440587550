/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-14 17:11:22
 * @LastEditTime: 2021-02-21 17:08:56
 * @LastEditors: dingxuejin
 */

import { lazy, PureComponent as Component } from "react";
import css from './index.module.scss';
import ItemGroup from "../../news/components/ItemGroup";
import SlideRight from "../../../components/animation/SlideRight";
import SlideLeft from "../../../components/animation/SlideLeft";

import scenes_factory from "@/assets/images/product/djTransport/scenes_factory.svg"
import scenes_driver from "@/assets/images/product/djTransport/scenes_driver.svg"

const Con = lazy(() => import("../components/intro/index"));
const Item = lazy(() => import("../components/item/index"));

class DjTransport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            introData: {
                bgImage: require("@/assets/images/product/djTransport/title_transport.jpg").default,
                text: "据中国物流与采购联合会统计数据显示：在产品的整个生产过程中，加工与检验的时间仅占5％，其余95％的时间则处于储存、输送和等待加工状态。制造成本的20％-40％，直接与物流环节的时间相关。"
            },
            layoutMap: {
                '-1': [[33, 33, 34]],
                0: [[66, 0, 34]],
                1: [[0, 66, 34]],
                2: [[33, 0, 67]],
            },
            active: -1
        };
    }

    onEnter = (e, index) => {
        this.setState({
            active: index
        })
    }

    onLeave = (e, index) => {
        this.setState({
            active: -1
        })
    }

    render() {
        let {layoutMap, active} = this.state;
        return (
            <div className={css["djTransport"]}>
                <Con introData={this.state.introData}></Con>
                <Item title="物流环节痛点">
                    <div className={css["con"]}>
                        <ItemGroup layout={[[32,32,32],[49, 49]]} colGap="0.24rem">
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>接单不可控</div>
                                <div className={css["des"]}>线下订单不可控，影响线上排产和配送交期</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>销售区域限制</div>
                                <div className={css["des"]}>客户不集中，需辐射的配送距离远</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>配货流程不完善</div>
                                <div className={css["des"]}>无法根据产能灵活调整车辆，司机方问题频出</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>装卸流程不规范</div>
                                <div className={css["des"]}>产品装卸不规范、运载率低、运输破损率高</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>物流配送不规范</div>
                                <div className={css["des"]}>配送线路无规划、物流成本居高不下</div>
                            </div>
                        </ItemGroup>
                    </div>
                </Item>
                <Item title="物流赋能解决方案" subTitle="专注于纸包装行业用车场景，打造服务体系。通过智能调车、智能配货、智能运输，整合车辆运力、完成物流赋能" bgcolor="#f5f5f5">
                    <ItemGroup layout={layoutMap[active]} className={css['item-group']} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>智能调车</div>
                            <div className={css["des"]}>提前规划车辆、智能调车、减少供需双方等待时间、提升装载率、减少空载率</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>智能配货</div>
                            <div className={css["des"]}>通过可视化的地图，实现一键配货。智能规划线路，提升配货效率、降低运输成本</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>智能运输</div>
                            <div className={css["des"]}>通过 GPS 系统及一路好运 App，实现物流自动跟踪，实时传递配送信息并同步给客户，提高交付时效</div>
                        </div>
                    </ItemGroup>
                </Item>
                <Item title="适用人群" color="#fff">
                    <div className={css["lst"]}>
                        <ItemGroup layout={[[50,50]]}>
                            <div className={css["itm"]}>
                                <div className={css["ico"]} style={{backgroundImage: `url(${scenes_factory})`}}></div>
                                <div className={css["inf"]}>
                                    <div className={css["num"]}>纸包装企业</div>
                                    <div className={css["des"]}>轻松解决客户物流费用高、货损追溯难，送货时间不准、运费难支付、车辆闲置、票据不规范等问题，快速匹配车辆、降低运输成本</div>
                                </div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]} style={{backgroundImage: `url(${scenes_driver})`}}></div>
                                <div className={css["inf"]}>
                                    <div className={css["num"]}>货车车主</div>
                                    <div className={css["des"]}>为货车车主快速对接货源，并提供精确匹配顺路货源。提升车辆使用率的同时，减少返程空载率。一趟行程，赚更多钱</div>
                                </div>
                            </div>
                        </ItemGroup>
                    </div>
                </Item>
                <Item title="产品优势">
                    <div className={css["itm"]}>
                        <div className={css["lef"]} >
                            <SlideRight display="inline-block">
                                <div className={css["titl"]}>精准配送</div>
                                <div className={css["des"]}>货主可快速匹配附近货车资源，解决找车难烦恼；使用单据管理模块，优化自身仓储、财务管理；通过车辆管理，提升车辆使用率，减少返程空载率。</div>
                            </SlideRight>
                        </div>
                        <SlideLeft display="inline-block">
                            <div className={css["rig"] + " " + css["img1"]}></div>
                        </SlideLeft>
                    </div>
                    <div className={css["itm"]}>
                        <SlideRight display="inline-block">
                            <div className={css["rig"] + " " + css["img2"]}></div>
                        </SlideRight>
                        <div className={css["lef"]} >
                            <SlideLeft display="inline-block">
                                <div className={css["titl"]}>精益物流</div>
                                <div className={css["des"]}>为广大货车主提供精准的货源匹配服务，完善的企业级运价和服务体系。货车主借助平台不仅可快速对接货源，获得更丰厚的收入回报，还能体验到更安心、专业的服务保障。</div>
                            </SlideLeft>
                        </div>
                    </div>
                </Item>
                {/*<Item title="产品下载">*/}
                {/*    <div className={css["lst"]}>*/}
                {/*        <div className={css["itm"]}>*/}
                {/*            <div className={css["titl"]}>用户端下载</div>*/}
                {/*            <div className={css["ico"]}>*/}
                {/*                <div className={css["ico-itm"]}>*/}
                {/*                    <div className={css["img"] + " " + css["ios"]}> </div>*/}
                {/*                    <div className={css["des"]}>iPhone下载</div>*/}
                {/*                </div>*/}
                {/*                <div className={css["ico-itm"]}>*/}
                {/*                    <div className={css["img"] + " " + css["andr"]}> </div>*/}
                {/*                    <div className={css["des"]}>安卓版下载</div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={css["itm"]}>*/}
                {/*            <div className={css["titl"]}>司机端下载</div>*/}
                {/*            <div className={css["ico"]}>*/}
                {/*                <div className={css["ico-itm"]}>*/}
                {/*                    <div className={css["img"] + " " + css["ios"]}> </div>*/}
                {/*                    <div className={css["des"]}>iPhone下载</div>*/}
                {/*                </div>*/}
                {/*                <div className={css["ico-itm"]}>*/}
                {/*                    <div className={css["img"] + " " + css["andr"]}> </div>*/}
                {/*                    <div className={css["des"]}>安卓版下载</div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={css["tip"]}></div>*/}
                {/*    </div>*/}
                {/*</Item>*/}
            </div >
        );
    }
}

export default DjTransport;
