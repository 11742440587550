/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-17 14:05:37
 * @LastEditTime: 2021-02-17 14:09:14
 * @LastEditors: dingxuejin
 */
import {lazy} from "react";

const NomalLayout = lazy(() => import("@/components/layout/nomalLayout"));
const Case = lazy(() => import("@/pages/case"));
const CaseParter = lazy(() => import("@/pages/case/subPages/caseParter"));

export default {
  path: "/case",
  description: "案例中心",
  details: "用“包装+互联网”商业模式，与合作伙伴携手发起赋能革命，推动纸板厂、纸箱厂全流程痛点解决方案落地，实现“降本、提效、增值”。",
  bgImage: require('@/assets/images/case/banner/banner_exp.jpg').default,
  component: Case,
  layout: NomalLayout,
  title: "案例中心-浙江东经科技股份有限公司-包装产业互联网研究院",
  desc: "用“包装+互联网”商业模式，与合作伙伴携手发起赋能革命，推动纸板厂、纸箱厂全流程痛点解决方案落地，实现“降本、提效、增值”。",
  keyword: "ERP系统，CRM智能营销系统，智能仓配系统，进销存管理系统，东经易网，易单来，易纸箱，易智配，专注纸包装行业",
  children: [
    {
      path: "/caseParter/:id",
      description: "案例中心",
      bgImage: require('@/assets/images/case/banner/banner_exp.jpg').default,
      component: CaseParter,
      layout: NomalLayout,
      nav: 0,
      tdks:{
        1:{
          title: "【浙江神泰包装股份有限公司】-客户故事-浙江东经科技股份有限公司",
          desc: "对于浙江神泰包装股份有限公司而言，东经帮其完成生产少数，司机质量培训、规范生产损耗、设备保养、专项生产质量、专项设备问题等几大模块的整改。大大降低了公司内耗，实现现金流，这才是合作双赢！与东经合作这个决定，无疑是正确的！",
          keyword: "【浙江神泰包装股份有限公司】-客户故事"
        },
        2:{
          title: "【德州久茂包装科技有限公司】-客户故事-浙江东经科技股份有限公司",
          desc: "德州久茂包装科技有限公司与东经合作之后，东经从资金、人员、技术、数字化平台等多个方面给予了全力支持，对其管理、销售、采购、生产、物流五大核心环节提供全流程、数字化、智能化技术赋能服务。",
          keyword: "【德州久茂包装科技有限公司】-客户故事"
        },
        3:{
          title: "【天津市朋创工贸股份有限公司】-客户故事-浙江东经科技股份有限公司",
          desc: "东诚专家组完善了合作伙伴朋创的质量体系。现场指导质检员对每日抽检出的不良品进行记录分析；对生产制程损耗记录方式、前端反馈的质量问题、生产线湿部与干部联动性问题进行沟通改善。从一个月开机13天，做到了每天开机！",
          keyword: "【天津市朋创工贸股份有限公司】-客户故事"
        },
        4:{
          title: "【青田三本包装有限公司】-客户故事-浙江东经科技股份有限公司",
          desc: "东经帮我们在技术管理上面提升了很多，整体的规范性，现在整体按照东经的管理模式引进很多的管理方案，包括设备、整改、机修、维护，到最后的送货服务跟质量跟踪都给我们提供了很大的帮助。",
          keyword: "【青田三本包装有限公司】-客户故事"
        }
      }
    }
  ]
}
