
/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-14 17:11:22
 * @LastEditTime: 2021-02-21 17:17:11
 * @LastEditors: dingxuejin
 */

import { lazy, PureComponent as Component } from "react";
import css from './index.module.scss';
import SlideRight from "../../../components/animation/SlideRight";
import SlideLeft from "../../../components/animation/SlideLeft";

const Con = lazy(() => import("../components/intro/index"));
const Item = lazy(() => import("../components/item/index"));
const Concat = lazy(() => import("./concat"));
const ItemGroup = lazy(() => import("../../news/components/ItemGroup"));
const Block = lazy(() => import("../../news/components/Block"));

class DjCardboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            introData: {
                bgImage: require("@/assets/images/product/djCardboard/title_cardboard.jpg").default,
                text: "目前市场产能需求过剩，行业大多数中小规模的纸板厂普遍面临着成本高、交期慢、订单散、杂、少等难题，经营效益很难提升。由于管理不当，企业各类资源的闲置和浪费直接增加了单平方加工成本。"
            },
            layoutMap: {
                '-1': [[50, 50],[33,33,34]],
                0: [[66, 34],[33,33,34]],
                1: [[34, 66],[33,33,34]],
                2: [[50, 50],[66,0,34]],
                3: [[50, 50],[33,67,0]],
                4: [[50, 50],[33,0,67]],
            },
            active: -1
        };
    }

    onEnter = (e, index) => {
        this.setState({
            active: index
        })
    }

    onLeave = (e, index) => {
        this.setState({
            active: -1
        })
    }

    render() {
        let {layoutMap, active} = this.state;
        return (
            <div className={css["djCardboard"]}>
                <Con introData={this.state.introData}></Con>
                <Item title="纸板厂痛点">
                    <div className={css["con"]}>
                        <ItemGroup layout={[[32,32,32],[49, 49]]} colGap="0.24rem">
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>成本高</div>
                                <div className={css["des"]}>原纸采购价格高、交期长、无话语权</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>单量散、杂、少</div>
                                <div className={css["des"]}>原纸采购价格高、交期长、无话语权</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>运输成本</div>
                                <div className={css["des"]}>客户分布散、配送路线多且复杂、运输成本高</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>售后效率低</div>
                                <div className={css["des"]}>质量处理信息不畅，费时费力</div>
                            </div>
                            <div className={css["itm"]}>
                                <div className={css["ico"]}></div>
                                <div className={css["titl"]}>交付周期不定</div>
                                <div className={css["des"]}>交货周期不好控制，总被客户催</div>
                            </div>
                        </ItemGroup>
                    </div>
                </Item>
                <Item title="五大领域 全流程赋能解决方案" bgcolor="#f5f5f5">
                    <ItemGroup layout={layoutMap[active]} className={css['item-group']} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>智能营销</div>
                            <div className={css["des"]}>易纸板通过智能营销系统，赋能纸板厂的销售模块。从客户管理、客户拜访、客户分布地图、数据看板四大板块服务企业的销售管理，实现销售管理的可视化，从而提升纸板厂的销售额及用户数，提升市场占有率。</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>原纸赋能</div>
                            <div className={css["des"]}>易纸板通过原纸 RFID 管理和原纸计划采购，实现纸板厂从原纸仓储管理到采购流程的全方位赋能。</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>物流赋能</div>
                            <div className={css["des"]}>易纸板通过东经旗下一路好运 App，实现物流赋能。平台通过可视化数据，动态掌握车辆，进行智能调配，三端互联，解决用户物流痛点。</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>自动技术升级</div>
                            <div className={css["des"]}>易纸板通过自主研发的多款自动化设备，大大降低人员的劳动强度、降低人工成本。</div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["tip"]}></div>
                            <div className={`${css["titl"]} ellipse`}>管理赋能</div>
                            <div className={css["des"]}>变更企业原有的管理方式、视野格局，从管理入手，用最少的资源创造最大的价值。</div>
                        </div>
                    </ItemGroup>
                </Item>
                <Item title="纸板厂专项服务">
                    <div className={css["con"]}>
                        <div className={css["itm"]}>
                            <div className={css["lef"]} >
                                <SlideRight display="inline-block">
                                    <div className={css["titl"]}>全流程测评</div>
                                    <div className={css["des"]}>通过对企业现场的全流程测评，输出定制化的测评报告及解决方案建议</div>
                                </SlideRight>
                            </div>
                            <SlideLeft display="inline-block">
                                <div className={css["rig"]}></div>
                            </SlideLeft>
                        </div>
                        <div className={css["itm"]}>
                            <SlideRight display="inline-block">
                                <div className={css["rig"]}></div>
                            </SlideRight>
                            <div className={css["lef"]} >
                                <SlideLeft display="inline-block">
                                    <div className={css["titl"]}>全领域运营</div>
                                    <div className={css["des"]}>以最新的产业互联网技术、工业大数据技术和智能制造技术与经验为基石，为纸板厂生产、供应、销售、人事、研发、财务六大领域提供高效智能的运营支持。</div>
                                </SlideLeft>
                            </div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["lef"]} >
                                <SlideRight display="inline-block">
                                    <div className={css["titl"]}>全方位赋能</div>
                                    <div className={css["des"]}>派遣专业人员驻厂指导、提供智能化系统对接、完善企业管理制度等一系列赋能服务</div>
                                </SlideRight>
                            </div>
                            <SlideLeft display="inline-block">
                                <div className={css["rig"]}></div>
                            </SlideLeft>
                        </div>
                    </div>
                </Item>
                <Item title="产品优势" color="#fff">
                    <div className={css["lst"]}>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["inf"]}>
                                <div className={css["num"]}>降本</div>
                                <div className={css["des"]}>
                                    <p>生产成本可控</p>
                                    <p>质量稳定可靠</p>
                                    <p>交期稳定准时</p>
                                </div>
                            </div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["inf"]}>
                                <div className={css["num"]}>提效</div>
                                <div className={css["des"]}>
                                    <p>生产效率提升</p>
                                    <p>订单管理高效</p>
                                    <p>对账管理轻松</p>
                                </div>
                            </div>
                        </div>
                        <div className={css["itm"]}>
                            <div className={css["ico"]}></div>
                            <div className={css["inf"]}>
                                <div className={css["num"]}>增值</div>
                                <div className={css["des"]}>
                                    <p>订单量增加</p>
                                    <p>利润率增加</p>
                                    <p>客户群增长</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
                <Item>
                    <Concat {...this.props}></Concat>
                </Item>
            </div >
        );
    }
}

export default DjCardboard;
