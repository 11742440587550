/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-17 14:03:09
 * @LastEditTime: 2021-02-17 14:03:44
 * @LastEditors: dingxuejin
 */
import { lazy } from "react";
const IndexLayout = lazy(() => import("@/components/layout/indexLayout"));
const Home = lazy(() => import("@/pages/home"));

export default {
    path: "/",
    description: "首  页",
    component: Home,
    layout: IndexLayout,
    title: "浙江东经科技股份有限公司-包装产业互联网研究院-为中小纸包装企业降本提效，价值倍增",
    desc: "以“包装+互联网” 模式，将互联网技术引入传统包装行业致力于打造中小企业降本提效，价值倍增的包装供应链服务平台，为纸包装用户提供进销存、纸包装交易平台、ERP、CRM、智能仓配系统等等一体化的信息化管理服务，赋能纸包装行业。助力包装行业的供给侧改革和中小包装企业的数字化转型升级。",
    keyword: "东经科技,东经易网,一路好运,易单来，易智配，易纸箱,包装，纸板团购,包装铺子,包装供应链服务平台，纸板交易平台，纸箱厂erp管理系统，纸板厂crm智能营销系统，智能仓配系统"
}
