// import {PureComponent} from 'react';
import css from './index.module.scss'

// class Tip extends PureComponent {
//   constructor() {
//     super();
//   }
//
//   render() {
//     let {children, index, onClick} = this.props;
//     return (
//         <div className={css['tip']} onClick={onClick}>
//           <div className={css['tip-title']}>
//             STEP {index}
//           </div>
//           <div className={`${css['tip-text']} ellipse`} title={children}>
//             {children}
//           </div>
//         </div>
//     );
//   }
// }
function Tip(props) {
  let {children, index, onClick} = props;
  return (
      <div className={css['tip']} onClick={onClick}>
        <div className={css['tip-title']}>
          STEP {index}
        </div>
        <div className={`${css['tip-text']} ellipse`} title={children}>
          {children}
        </div>
      </div>
  );
}

export default Tip;