import {PureComponent} from 'react';
import css from './index.module.scss'
import arrowIcon from '../../../../assets/images/news/icon_more_down.svg'
import arrowIcon_hover from '../../../../assets/images/news/icon_more_down_light.svg'

class AllBtn extends PureComponent {
  constructor() {
    super();
  }

  render() {
    return (
        <div className={css['all-btn']} onClick={this.props.onClick}>
          <span className={css['all-btn-txt']}>查看全部</span>
          <span className={css['all-btn-icon']}>
            <img className={css['normal']} src={arrowIcon}/>
            <img className={css['hover']} src={arrowIcon_hover}/>
          </span>
        </div>
    );
  }
}

export default AllBtn;