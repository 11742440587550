/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-17 14:04:54
 * @LastEditTime: 2021-02-19 19:17:46
 * @LastEditors: dingxuejin
 */
import { lazy } from "react";
const NomalLayout = lazy(() => import("@/components/layout/nomalLayout"));
const Product = lazy(() => import("@/pages/product"));

const DjNet = lazy(() => import("@/pages/product/djNet"));
const DjTransport = lazy(() => import("@/pages/product/djTransport"));
const DjCardboard = lazy(() => import("@/pages/product/djCardboard"));
const DjCarton = lazy(() => import("@/pages/product/djCarton"));

export default {
    path: "/product",
    description: "产品中心",
    redirect: "/djNet",
    children: [
        // {
        //     path: "/djNet",
        //     description: "东经易网",
        //     details: "中小企业降本提效、价值倍增的包装供应链服务平台",
        //     bgImage: require('@/assets/images/product/djNet/banner_bg_yiwang.jpg').default,
        //     component: DjNet,
        //     layout: NomalLayout,
        // },
        // {
        //     path: "/djTransport",
        //     description: "一路好运",
        //     details: "整合车辆运力，实现高效率低成本的配送，改变纸包装供应链交付",
        //     bgImage: require('@/assets/images/product/djTransport/banner_transport.jpg').default,
        //     component: DjTransport,
        //     layout: NomalLayout,
        // },
        // {
        //     path: "/djCardboard",
        //     description: "易纸板",
        //     details: "纸板厂全流程痛点赋能解决方案",
        //     bgImage: require('@/assets/images/product/djCardboard/banner_cardboard.jpg').default,
        //     component: DjCardboard,
        //     layout: NomalLayout,
        // },
        // {
        //     path: "/djCarton",
        //     description: "易纸箱",
        //     details: "纸箱厂全流程精细化管理赋能解决方案",
        //     bgImage: require('@/assets/images/product/djCarton/banner_bg_yizhixiang.jpg').default,
        //     component: DjCarton,
        //     layout: NomalLayout,
        // }
    ]
}
