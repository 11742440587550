import { info } from '../base-service/service';
import enums from '../../utils/enum';
export default {
    // 获取企业荣誉数据
    honorList(data = {}) {
        data.ftype = enums.blocks.honor.value;
        return info('/queryListByPage.do', data);
    },
    // 获取企业动态数据
    dynamicList(data = {}) {
        data.ftype = enums.blocks.dynamic.value;
        return info('/queryListByPage.do', data);
    },
    // 获取行业资讯数据
    informationList(data = {}) {
        data.ftype = enums.blocks.information.value;
        return info('/queryListByPage.do', data);
    },
    // 获取视频中心数据
    videoList(data = {}) {
        data.ftype = enums.blocks.video.value;
        return info('/queryListByPage.do', data);
    },
    // 获取详细数据
    getInfo(data = {}) {
        return info('/selectById.do', data);
    },
    // 获取数据
    getList(data = {}) {
        return info('/queryListByPage.do', data);
    }
};
