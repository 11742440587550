import {PureComponent} from 'react';
import css from './index.module.scss'
import Ellipse from "../../../news/components/Ellipse";
import icon_more from '@/assets/images/case/icon_more.svg';

class HoverItem extends PureComponent {
  constructor() {
    super();
  }

  render() {
    let {item = {}, onClick} = this.props;
    let {img, intro, name = ''} = item;
    return (
        <div className={css['item']} onClick={onClick}>
          <div className={css['item-img']}>
            <p className={css['item-img-name']}>{name}</p>
            <img src={img} alt=""/>
          </div>
          <div className={css['item-text']}>
            <div>
              <p className={css['item-text-intro']}><Ellipse max={105}>{intro}</Ellipse></p>
              <p className={css['item-text-more']}>
                <span>了解更多</span>
                <span className={css['item-text-more-img']} style={{background: `url('${icon_more}')`}}></span>
                {/*<img className={css['item-text-more-img']} src={icon_more}></img>*/}
              </p>
            </div>
          </div>
        </div>
    );
  }
}

export default HoverItem;
