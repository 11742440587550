import { message } from 'antd';

var config = {
  baseURL: '/officialsite', //url根路径
  onResponse(res) { //后端返回数据时执行（不管success时true还是false）
    return res;
  },
  onError(data) { //请求没有连接上后端时执行
    message.error(data.msg);
  },
  onFailure(data) { //请求没有连接上后端和success为false时执行
  }
};

export default config;
