/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-16 16:43:44
 * @LastEditTime: 2021-02-20 18:43:53
 * @LastEditors: dingxuejin
 */
import { PureComponent as Component } from "react";
import css from './index.module.scss';

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    checkedChangeHandler = (e, item) => {
        item["checked"] = e.target.checked;
        this.props.onchangeCheck(item)
    }
    render() {
        return (
            <div className={css["checkbox"]}>
                <input type="checkbox" className={css["input"]} checked={this.props.checked} onInput={(e) => this.checkedChangeHandler(e, this.props.item)} />
                <label className={this.props.error ? css["text"] + " " + css["error"] : css["text"]}>{this.props.item.label}</label>
            </div>
        );
    }
}

export default Checkbox;