/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-20 14:39:37
 * @LastEditTime: 2021-02-21 14:01:42
 * @LastEditors: dingxuejin
 */

import { lazy, PureComponent as Component } from "react";
import css from './index.module.scss';
const Checkbox = lazy(() => import("../checkbox"));

class checkGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // value: JSON.parse(JSON.stringify(this.props.checkOption)),
            // defaultValue: this.props.defaultValue,
            error: false
        };
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    checkedChangeHandler = (val) => {
        let arr = [];
        this.props.checkOption.forEach((item) => {
            if (val.value == item.value) {
                item = val
            }
            if (item.checked) {
                arr.push(item.value);
            }
        })
        this.setState({
            error: false
        })
        this.props.onchangeCheck(arr);
    }
    change() {
        this.setState({
            error: true
        })
    }
    reset = () => {
        this.props.checkOption.map(o=>o.checked = false)
        this.setState(state => ({
            error: false
        }))
    }
    render() {
        return (
            <div className={css["che"]}>
                {
                    this.props.checkOption.map((item, index) => {
                        return (< div className={css["itm"]} key={index}><Checkbox item={item} onchangeCheck={this.checkedChangeHandler} error={this.state.error}></Checkbox></div>)
                    })
                }
            </div >
        );
    }
}

export default checkGroup;