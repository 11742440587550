import { combineReducers } from 'redux-immutable';
import headerReducer from './headerReducer'
import appReducer from './appReducer'

export * from './headerReducer';

const reducers = combineReducers({
  header: headerReducer,
  app: appReducer
});
export default reducers;
