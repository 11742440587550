import React, {PureComponent as Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {CSSTransition} from 'react-transition-group';
import PropTypes from 'prop-types';
import {addScrollBottomListener, concatClass, parseClass, removeScrollBottomListener} from "../../../../utils/methods";
import css from "./index.module.scss"

// 自下划入动画组件
@connect(state => ({isMobile: state.getIn(['app', 'isMobile'])}),)
class SlideUp extends Component{
  constructor() {
    super();
    this.anchor = React.createRef();
    this.state = {
      isShow: false
    }
  }

  static defaultProps={
    type: 'scroll',
    delay: 0
  }

  static propTypes = {
    // 类型： 控制立即执行还是滚动执行
    type: PropTypes.oneOf(['scroll', undefined]),
    // 动画名
    classNames: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func, PropTypes.array]),
    // 元素的display
    display: PropTypes.string,
    // 动画延迟
    delay: PropTypes.number,
    // 产生动画效果的内容
    children: PropTypes.node,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isMobile !== this.props.isMobile) {
      this.setState({isShow: true});
    }
  }

  componentDidMount() {
    setTimeout(()=>{
      let {type} = this.props;
      if (type === 'scroll') {
        this.listenScroll();
      } else {
        this.start();
      }
    })
  }

  componentWillUnmount() {
    let {type} = this.props;
    if (type === 'scroll') {
      removeScrollBottomListener(window, this.anchor.current, this.start);
    }
  }

  start = (e, bool) => {
    if (this.props.isMobile) bool = true;
    if (this.state.isShow === bool) return;
    this.setState({isShow: bool})
  };

  listenScroll = () => {
    addScrollBottomListener(window, this.anchor.current, this.start, true, {offset: -document.documentElement.clientHeight / 4})
  };

  render() {
    let {children, classNames, display, delay} = this.props;
    let {isShow} = this.state;
    return (
      <>
        <i ref={this.anchor} className={css['anchor']}></i>
        <CSSTransition
          {...this.props}
          in={isShow}
          timeout={delay}>
          <div className={concatClass(css["css-transition"], parseClass(classNames))} style={{display}}>
            {children}
          </div>
        </CSSTransition>
      </>
    );
  }
}
export default SlideUp;
