import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import icon_next from '../../../../assets/images/news/icon_next.svg'
import icon_next_hover from '../../../../assets/images/news/icon_next_hover.svg'
import icon_prev from '../../../../assets/images/news/icon_prev.svg'
import icon_prev_hover from '../../../../assets/images/news/icon_prev_hover.svg'
import css from './index.module.scss'
import {throttle} from "../../../../utils/methods";

// 渐变函数
function transition(start, end, fn, time = 500, delay = 20) {
  let maxIndex = time / delay;
  let unit = (end - start) / maxIndex;
  let index = 0;
  let timer = setInterval(() => {
    index++;
    let isLast = maxIndex === index;
    try {
      fn(isLast ? end : start + unit * index);
    } catch (e) {
      console.error(e);
    }
    if (isLast) {
      clearInterval(timer);
    }
  }, delay);
  return timer;
}

class Swiper extends PureComponent {
  constructor() {
    super();
    this.swiper = React.createRef();
    this.slider = React.createRef();
    this.isMoving = false;
    this.left = 0;
    this.state = {
      width: null,
      activeIndex: 0,
    }
  }

  static propTypes = {
    // 高度
    height: PropTypes.string,
    // 轮播图切换后的回调
    afterChange: PropTypes.func,
    children: PropTypes.node,
  }

  render() {
    let children = this.props.children || [];
    let {width, activeIndex} = this.state;
    return (
        <div ref={this.swiper} className={css['swiper']} style={{height: this.props.height}}>
          <div ref={this.slider} className={css['swiper-list']} style={{width: width * children.length + 'px'}}>
            {children.map((child, index) => (
                <div key={index} className={css['swiper-list-item']} style={{width: width + 'px'}}>{child}</div>
            ))}
          </div>
          <div className={`${css['swiper-btn']} ${css['prev']}`} onClick={this.prev}>
            <img className={css['swiper-icon-normal']} src={icon_prev} alt=""/>
            <img className={css['swiper-icon-hover']} src={icon_prev_hover} alt=""/>
          </div>
          <div className={`${css['swiper-btn']} ${css['next']}`} onClick={this.next}>
            <img className={css['swiper-icon-normal']} src={icon_next} alt=""/>
            <img className={css['swiper-icon-hover']} src={icon_next_hover} alt=""/>
          </div>
          <div className={css['swiper-dots']}>
            {
              children.map((o, idx) => (
                  <span key={idx}
                        className={`${css['swiper-dot']} ${activeIndex === idx ? css['active'] : ''}`}
                        onClick={()=>this.go(idx)}
                  ></span>)
              )
            }
          </div>
        </div>
    );
  }

  transition = (() => {
    let timmer;
    return (...argv)=>{
      clearInterval(timmer);
      timmer = transition(...argv);
    }
  })();

  next = () => {
    this.go((this.state.activeIndex + 1) % this.props.children.length);
  };

  prev = () => {
    this.go((this.state.activeIndex - 1 + this.props.children.length) % this.props.children.length);
  };

  go = (idx) => {
    let {afterChange, children = []} = this.props;
    if (this.isMoving || children.length - 1 < idx || this.state.activeIndex === idx) return;
    // this.setState({activeIndex: idx});
    this.isMoving = true;
    let end = - this.state.width * idx;
    this.transition(this.left, end, (num)=>{
      this.left = num;
      this.slider.current.style.left = num + 'px';
      if (end === num) {
        this.isMoving = false;
        this.setState({activeIndex: idx});
        afterChange && afterChange(idx);
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth)
  }

  componentDidMount() {
    window.addEventListener('resize', this.setWidth)
    setTimeout(this.setWidth);
  }

  setWidth = throttle(20, () => {
    this.setState({
      width: this.swiper.current.offsetWidth
    })
  })
}

export default Swiper;
