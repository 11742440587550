import axios from 'axios';
import BaseApi from './baseApi';
import configCheck from './configCheck';
import {transError, defaultExecOn} from './defaultConfig';
import {getObjectType, checkType, blobToString} from '../../utils/methods';

/**
 * 向option中加入传参
 * @param option
 * @param method
 * @param param
 */
function addParams(option, method, param) {
  /get|delete|head/.test(method) && (option.params = param);
  /post|put|patch/.test(method) && (option.data = param);
}

/**
 * 兼容之前直接使用CancelToken的用法
 * @param option
 * @param method
 * @param param
 */
function compatibleConfig(config = {}) {
  let {token, cancel} = config;
  let isCancelToken = token && cancel;
  if (isCancelToken) {
    return {
      cancelToken: token
    }
  }
  return config;
}

/**
 * 执行传入的配置项中的on前缀的方法
 * @param config
 * @param functionName
 * @param res
 */
function execOnFunction(config = {}, functionName, res) {
  defaultExecOn(functionName, res, config);
  let func = config[functionName];
  if (getObjectType(func) === '[object Function]') {
    func(res);
  }
}

/**
 * 统一处理返回的response
 * @param res
 * @param resolve
 * @param reject
 */
function dealResponse(res, resolve, reject, _config) {
  // 当接口返回的数据中没有success字段时，返回data整体，为了兼容文件下载
  let promise = Promise.resolve(res);
  if (checkType(res.data, 'Blob') && /application\/json/.test(res.data.type)) {
    promise = blobToString(res.data).then(json => {
      res.data = JSON.parse(json);
      return res;
    });
  }
  promise.then((res) => {
    if (checkType(res.data, 'Blob')) {
      resolve(res.data);
    } else {
      if (_config.isDealResponse) {
        try {
          // 基于公司规范，通过success来判断业务接口的处理情况，有些情况可能没有success，也视为成功
          switch (res.data.success) {
            case 'undefined':
              resolve(res.data);
              break;
            case true:
              resolve(res.data.data);
              break;
            case false:
              execOnFunction(_config, 'onFailure', res.data);
              reject(res.data);
              break;
            default:
              resolve(res.data);
          }
        } catch (e) {
          console.error('-- 后端数据返回出错：' + e);
          reject(res);
        }
      } else {
        resolve(res)
      }
    }
  });
}

/**
 * 默认配置，不包括处理函数
 * @type {{isDealRespones: boolean}}
 */
const defaultConfig = {
  isDealResponse: true
};

/**
 * 请求处理逻辑
 * @param baseApi
 * @param method
 * @param _config
 * @returns {function(*, *, *=, *=, *)}
 */
function createHttpFn(baseApi, method, config = {}) {
  return (url, param, cfg = {}) => {
    let option = {
      ...config,
      url,
      method,
      ...compatibleConfig(cfg),
    };
    addParams(option, method, param);
    return request.bind(baseApi)(option);
  };
}

/**
 * 请求方法
 * @param _config
 * @returns {Promise<any>}
 */
function request(_config) {
  let config = Object.assign({}, defaultConfig, this.httpConfig, _config);
  return new Promise((resolve, reject) => {
    this(config).then(res => {
      execOnFunction(config, 'onResponse', res);
      dealResponse(res, resolve, reject, config);
    }).catch(e => {
      if (e.response || e.code === 'ECONNABORTED') {
        // then解析出错时，会被catch捕获
        let err = transError(e);
        execOnFunction(config, 'onError', err);
        // execOnFunction(config, 'onFailure', err, option);
        reject(e);
      }
    });
  });
}

/**
 * 获得处理后的请求方法
 * @param baseApi
 * @param config
 * @returns {{request: any, post: function(*, *, *=, *=, *), get: function(*, *, *=, *=, *), put: function(*, *, *=, *=, *), delete: function(*, *, *=, *=, *), head: function(*, *, *=, *=, *), patch: function(*, *, *=, *=, *), download_post: function(*, *, *=, *=, *), download_get: function(*, *, *=, *=, *)}}
 */
function getHttps(baseApi) {
  return {
    request: request.bind(baseApi),
    post: createHttpFn(baseApi, 'post'),
    get: createHttpFn(baseApi, 'get'),
    put: createHttpFn(baseApi, 'put'),
    'delete': createHttpFn(baseApi, 'delete'),
    head: createHttpFn(baseApi, 'head'),
    patch: createHttpFn(baseApi, 'patch'),
    download_post: createHttpFn(baseApi, 'post', {responseType: 'blob'}),
    download_get: createHttpFn(baseApi, 'get', {responseType: 'blob'}),
  }
}

/**
 * http请求对象
 * @param config
 */
function HttpFactory(config = {}) {
  configCheck(config);
  const baseApi = new BaseApi(config);
  baseApi.httpConfig = config;
  let instance = request.bind(baseApi);
  Object.assign(instance, axios, baseApi, getHttps(baseApi));
  return instance;
}

export default HttpFactory;

