import {lazy, PureComponent as Component} from "react";
import lottie from 'lottie-web';
import bg from './images/bg.png'
import css from './index.module.scss'

class AppFirstScreen extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    lottie.loadAnimation({
      container: document.querySelector("#appFirstScreen_animate"), // 容器
      renderer: "svg",
      loop: true,
      autoplay: true,
      // animationData: {}, //如果使用的是JSON
      path: "/lottieJson/data_line.json" // the path to the animation json
    });
    lottie.loadAnimation({
      container: document.querySelector("#appFirstScreen_playBtn"), // 容器
      renderer: "svg",
      loop: true,
      autoplay: true,
      // animationData: {}, //如果使用的是JSON
      path: "/lottieJson/data_play.json" // the path to the animation json
    });
  }

  render() {
    return (
      <div className={css['appFirstScreen']}>
        <p className={css['appFirstScreen_title']}>您成功，我发展</p>
        <p className={css['appFirstScreen_subTitle']}>打造中小企业降本提效、价值倍增的包装供应链服务平台</p>
        <div id="appFirstScreen_animate" className={css['appFirstScreen_animate']}>
          <img src={bg} alt=""/>
        </div>
        <p className={css['appFirstScreen_des']}>
          东经科技以“包装+互联网” 模式<br/>
          打造中小企业降本提效，价值倍增包装供应链服务平台<br/>
          实现全产业链各利益相关方共赢
        </p>
        <div className={css['appFirstScreen_playBtn']} onClick={this.props.onPlay}>
          <div id="appFirstScreen_playBtn" className={css['appFirstScreen_playBtnIcon']}></div>
          <span>观看视频</span>
        </div>
      </div>
    )
  }
}

export default AppFirstScreen;
