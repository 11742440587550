import {useRef, useEffect} from 'react';
import {addScrollBottomListener, removeScrollBottomListener} from "../utils/methods";

// 目标元素滚动到底部的hook
module.exports = function useElScrollToBottom(callback) {
  let ref = useRef();
  useEffect(() => {
    let el = ref.current;

    function handleScroll(e, bool) {
      if (!bool) return;
      removeScrollBottomListener(window, el, handleScroll);
      callback && callback();
    }

    if (el) {
      setTimeout(() => {
        addScrollBottomListener(window, el, handleScroll, true);
      })
    }

    return () => {
      removeScrollBottomListener(window, el, handleScroll);
    }
  }, []);
  return {ref};
}