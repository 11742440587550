/*
 * @Description:
 * @Author: dingxuejin
 * @Date: 2021-02-20 20:21:03
 * @LastEditTime: 2021-02-21 14:09:01
 * @LastEditors: dingxuejin
 */
import { PureComponent as Component } from "react";
import css from './index.module.scss';
import btn_close from "@/assets/images/about/btn_close.svg";

class Msg extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    close = () => {
        this.props.onChangeMsg();
    }
    render() {
        return (
            <div>
                {
                    this.props.msgInfo ? < div className={css["con"]}>
                        <div className={this.props.msgInfo.type == "error" ? css["msg"] + " " + css["err"] : css["msg"]}>
                            <div className={css["ico"]}>
                                <div className={css["bz"]}></div>
                            </div>
                            <div className={css["tex"]}>{this.props.msgInfo.text}</div>
                            <div className={css["close"]} onClick={this.close}><img src={btn_close} alt=""/></div>
                        </div>
                    </div > : ""
                }

            </div>
        );
    }
}

export default Msg;